import { render, staticRenderFns } from "./CompanyProfile.vue?vue&type=template&id=48c91181&scoped=true"
import script from "./CompanyProfile.vue?vue&type=script&lang=js"
export * from "./CompanyProfile.vue?vue&type=script&lang=js"
import style0 from "./CompanyProfile.vue?vue&type=style&index=0&id=48c91181&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c91181",
  null
  
)

export default component.exports