<template>
  <div style="padding-top: calc(100px + 0.390625vw);">
    <div class="background">
      <img src="/company_profile/background.png" style="width:50%" alt="background">
    </div>
    <h6 class="font-weight-bold mb-4 mt-4 text-center" style="margin-left:20px; font-size: calc(30px + 0.390625vw); color: brown;">Perusahaan</h6>
    <p class="center pb-4" style="font-size: calc(30px + 0.390625vw); text-align: center; padding-left: 7%; padding-right: 7%;">
      PT. Batavia Karya Mandiri atau biasa dikenal Bakama merupakan salah satu perusahaan
      ekspedisi di dalam negeri dan telah melayani beberapa perusahaan yang dikagumi di
      seluruh dunia. Dengan demikian, kami akan memberikan layanan terbaik dan
      menerapkannya untuk mencapai tujuan Anda.
    </p>

    <flickity ref="flickity" :options="flickityOptions" class="pt-4 pb-4" style="max-width: 90%;margin: auto;">
      
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-1.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/halal.png" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-2.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-3.png" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-4.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-5.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-6.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-7.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-8.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-9.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-10.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-11.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-12.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-13.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-14.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-15.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="carousel-cell mr-4">                    
        <img src="/company_profile/img-16.jpg" class="img-slider" alt="img-slider">
      </div>
    </flickity>
    <!-- if you don't want to use the buttons Flickity provides -->
    <!-- <button @click="previous()">Custom Previous Button</button>
    <button @click="next()">Custom Next Button</button> -->
    <table class="mt-4" style="margin-left: auto; margin-right: auto;">
      <tr>
        <td class="font-weight-bold pl-2" style="font-size: calc(20px + 0.390625vw); color: brown;">Tujuan Kami</td>
        <td></td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td></td>
      </tr>
      <tr>
        <td class="font-weight-bold pl-2" style="font-size: calc(20px + 0.390625vw);">Visi</td>
        <td class="pr-2" style="font-size: calc(20px + 0.390625vw);">
          Membantu klien dalam meminimalisir biaya
          <br>
          dan mengingatkan efesiensi internal melalui
          <br>
          layanan logistik.
        </td>
      </tr>
      <tr>
        <td colspan="2" class="td-line-horizontal">
          <hr class="line-horizontal">
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="font-weight-bold pl-2" style="font-size: calc(20px + 0.390625vw);">Misi</td>
        <td class="pr-2" style="font-size: calc(20px + 0.390625vw);">
          Memberikan pengalaman terbaik dibidang
          <br>
          pengiriman logistik.
        </td>
      </tr>
    </table>

    <!-- <div class="row mt-4" style="margin-bottom: 10%;">
      <div class="col-12 col-lg-5">
        <h6 class="font-weight-bold text-center" style="margin-left:20px; font-size: calc(30px + 0.390625vw); color: brown;">Sejarah Bakama</h6>
        <img src="/company_profile/pesawat.png" class="" style="width:80%;" alt="pesawat">
      </div>
      <div class="col-12 col-lg-2 mb-4 div-line-vertical">
        <hr class="line-vertical">
      </div>
      <div class="col-12 col-lg-5" style="padding-left: 5%; padding-right: 7%;">
        Batavia Karya Mandiri didirikan pada tahun 2016,
        <br>
        didirikan oleh para ahli profesional yang memiliki
        <br>
        pengalaman lebih dari 15 tahun di bidang keuangan
        <br>
        pada umumnya, administrasi, pajak, jasa material dan 
        <br>
        logistik. Bakama, pengalaman perusahaan > 4 tahun
        <br>
        sebagai tenaga ahli yang independen dan spesifik
        <br>
        Mitra terpercaya dan handal untuk menangani
        <br>
        transaksi rahasia Anda seperti penggajian, transaksi
        <br>
        internal dan eksternal perusahaan, dll. 
      </div>
    </div> -->

    <table class="" style="margin-left: auto; margin-right: auto; margin-top: 10%;">
      <tr>
        <td width="45%">
          <h6 class="font-weight-bold text-center" style="font-size: calc(30px + 0.390625vw); color: brown;">Sejarah Bakama</h6>
          <img src="/company_profile/pesawat.png" class="" style="width:80%;" alt="pesawat">
        </td>
        <td width="5%">
          <!-- <hr class="line-vertical">
          <hr class="line-vertical" style="border: none; border-right: 2px solid red; height: 100vh;"> -->
          <div class="vertical-line"></div>
        </td>
        <td width="45%" style="font-size: calc(20px + 0.390625vw);">
          <div class="mr-3">
            Batavia Karya Mandiri didirikan pada tahun 2016,
            <br>
            didirikan oleh para ahli profesional yang memiliki
            <br>
            pengalaman lebih dari 15 tahun di bidang keuangan
            <br>
            pada umumnya, administrasi, pajak, jasa material dan 
            <br>
            logistik. Bakama, pengalaman perusahaan > 4 tahun
            <br>
            sebagai tenaga ahli yang independen dan spesifik
            <br>
            Mitra terpercaya dan handal untuk menangani
            <br>
            transaksi rahasia Anda seperti penggajian, transaksi
            <br>
            internal dan eksternal perusahaan, dll. 
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
export default {
  components: {
    Flickity
  },
  
  data() {
    return {
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        freeScroll: true
      }
    }
  },
  
  methods: {
    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    }
  }
}
</script>

<style scoped>
  .background{
    width: 100%;
    position: absolute;
    height:900px;
    z-index: -1;
    opacity: 0.5;
    text-align: center;
  }

  .img-slider {
    width: calc(250px + 0.390625vw); 
    height:250px ; 
    border-radius: 10px;
    object-fit: cover;
  }

  /* LINE HORIZONTAL */
    hr.line-horizontal {
      border-top: 2px dashed rgb(131, 131, 131);
      height: 2px;
      position: relative;
    }

    hr.line-horizontal::before,
    hr.line-horizontal::after {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      width: 10px;
      height: 10px;
      background-color: rgb(131, 131, 131);
      border-radius: 50%;
    }

    hr.line-horizontal::before {
      left: -5px;
    }

    hr.line-horizontal::after {
      right: -5px;
    }
  /* LINE HORIZONTAL */
  /* LINE VERTICAL */
    /* hr.line-vertical {
      border-top: 2px solid rgb(131, 131, 131);
      height: 2px;
      position: relative;
      top: 50%;
      transform:rotate(90deg);
    }

    hr.line-vertical::before,
    hr.line-vertical::after {
      content: '';
      display: block;
      position: absolute;
      transform: translateY(-50%);
      top: -1px;
      width: 10px;
      height: 10px;
      background-color: rgb(131, 131, 131);
      border-radius: 50%;
    }

    hr.line-vertical::before {
      left: -5px;
    }

    hr.line-vertical::after {
      right: -5px;
    } */

    .vertical-line {
      position: relative;
      height: 35vw; /* Tinggi garis */
      width: 2px; /* Lebar garis */
      background-color: rgb(131, 131, 131);
    }

    .vertical-line::before,
    .vertical-line::after {
        content: '';
        position: absolute;
        width: 10px; /* Diameter bulatan */
        height: 10px; /* Diameter bulatan */
        border-radius: 50%; /* Membuat bulatan */
      background-color: rgb(131, 131, 131);

    }

    .vertical-line::before {
        top: 0; /* Atur di atas ujung garis */
        left: -4px; /* Atur agar tepat di tengah */
    }

    .vertical-line::after {
        bottom: 0; /* Atur di bawah ujung garis */
        left: -4px; /* Atur agar tepat di tengah */
    }
  /* LINE HORIZONTAL */

/* height drag image slider di hardcode, karna ketika on ready tidak muncul, ketika browser di perkecil ukurannya baru muncul */
  .flickity-viewport{
    height:361px !important;
  }

  /* css view */
  @media only screen and (min-width:320px)
  { 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .td-line-horizontal{
      padding-left: 3%; 
      padding-right: 6%;
    }
    .div-line-vertical{
      padding-left: 5%; 
      padding-right: 8%;
    }
    /* hr.line-vertical {
      width: 300px;
    } */
  }
  @media only screen and (min-width:481px)  
  { 
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
  }
  @media only screen and (min-width:641px)  
  { 
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
  }
  @media only screen and (min-width:961px)  
  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    hr.line-vertical {
      transform:rotate(90deg);
    } 
    .td-line-horizontal{
      padding-left: 0%; 
      padding-right: 0%;
    }
    .div-line-vertical{
      padding-left: 0%; 
      padding-right: 0%;
    }
    /* hr.line-vertical {
      right:100%;
      width: 500px;
    } */
  }
  @media only screen and (min-width:1025px) 
  { 
    /* big landscape tablets, laptops, and desktops */ 
    hr.line-vertical {
      transform:rotate(90deg);
    } 
    .td-line-horizontal{
      padding-left: 0%; 
      padding-right: 0%;
    }
    .div-line-vertical{
      padding-left: 0%; 
      padding-right: 0%;
    }
    /* hr.line-vertical {
      right:60%;
      width: 500px;
    } */
  }
  @media only screen and (min-width:1281px) 
  { 
    /* hi-res laptops and desktops */
    hr.line-vertical {
      transform:rotate(90deg);
    } 

    .td-line-horizontal{
      padding-left: 0%; 
      padding-right: 0%;
    }
    .div-line-vertical{
      padding-left: 0%; 
      padding-right: 0%;
    }
    /* hr.line-vertical {
      right:60%;
      width: 500px;
    } */
  }
</style>